import Button from "@components/library/Button";
import { ArrowUpRightIcon, OpenAIIcon } from "@components/library/Icons";
import { TrendingUpIcon, UserGroupIcon } from "@heroicons/react/outline";
import {
  EventPage,
  EventPurpose,
  EventSection,
  analytics,
} from "services/analytics.service";
import { DOCS_LINK } from "utils/constants";
import { CodeSnippet } from "./CodeSnippet";

interface TextWithButtonProps {
  button: React.ReactNode;
  header: string;
  description: string;
}

export const TextWithButton = ({
  header,
  description,
  button,
}: TextWithButtonProps) => (
  <div className="flex flex-row items-center justify-between gap-16 md:max-w-[250px] md:flex-col md:items-start md:gap-24 md:text-16-24">
    <div className="flex flex-col gap-8">
      <div className="text-14-20 font-medium text-gray-950 dark:text-white">
        {header}
      </div>
      <div className="text-12-16 text-gray-400 dark:text-gray-300">
        {description}
      </div>
    </div>
    {button}
  </div>
);

interface IconWithTextProps {
  icon: React.ReactNode;
  header: string;
  description: string;
}

export const IconWithText = ({
  icon,
  header,
  description,
}: IconWithTextProps) => (
  <div className="flex flex-col gap-12 md:gap-32">
    <div className="flex flex-row items-start gap-16 text-14-20 font-medium md:gap-24 md:text-16-24">
      <div className="mt-2 rounded-[12px] p-10 shadow-d dark:bg-gray-800 md:p-12">
        {icon}
      </div>
      <div className="pt-10 text-gray-950 dark:text-white">
        {header}{" "}
        <span className="text-gray-400 dark:text-gray-300">{description}</span>
      </div>
    </div>
  </div>
);

export const DevelopersAndOthersSection = () => {
  return (
    <div className="container-lg dark mx-12 grid max-w-[1160px] grid-cols-12 text-start md:mx-auto">
      <div className="col-span-full text-start">
        <div className="justify-start text-13-14 font-bold  text-blue-400">
          Developers
        </div>
        <h2 className="mt-16 flex flex-col self-stretch font-display text-[24px] leading-[100%] text-white md:mt-40 md:text-[40px]">
          Easy integration into your production applications
        </h2>
      </div>
      <div className="col-span-full mt-32 text-left text-white md:col-span-5 md:mt-64">
        <div className="flex flex-col space-y-32">
          <div className="flex flex-col space-y-28 text-14-20 md:text-16-24">
            <p>
              Your prompts are version controlled, and deployments can be
              executed by PMs or within your existing CI/CD system.
            </p>
          </div>
          <div className="flex flex-col gap-24 md:gap-32">
            <IconWithText
              icon={<UserGroupIcon className="h-16 w-16" />}
              header={"Collaboration."}
              description={
                "Enable domain experts to contribute to prompt engineering — all version controlled."
              }
            />
            <IconWithText
              icon={<OpenAIIcon className="h-16 w-16 fill-white" />}
              header={"All the models."}
              description={"OpenAI, Anthropic, Llama2 and your own models."}
            />
            <IconWithText
              icon={<TrendingUpIcon className="h-16 w-16" />}
              header={"Evaluation."}
              description={
                "Manage test data, define your own metrics and integrate into your CI/CD workflows."
              }
            />
          </div>
          <div className="flex w-full flex-col gap-24 md:flex-row md:gap-32 pl-60">
            <div>
              <Button
                size={36}
                className="border border-gray-700 font-bold text-gray-900 dark:text-white"
                styling="outline"
                shade="gray"
                href={DOCS_LINK}
                IconRight={ArrowUpRightIcon}
                target="_blank"
                onClick={() => {
                  analytics.track("Button Clicked", {
                    page: EventPage.Home,
                    section: EventSection.Developer,
                    purpose: EventPurpose.Documentation,
                    url: DOCS_LINK,
                  });
                }}
              >
                View Docs
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-full flex flex-col items-start justify-between gap-48 pt-48 text-left text-white md:col-start-7 md:col-end-13 md:gap-64 md:pt-64">
        <CodeSnippet />
      </div>
    </div>
  );
};
