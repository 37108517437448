import { ChartBarIcon, ModelIcon, UserIcon } from "@components/library/Icons";
import Image from "next/image";

interface IconWithTextProps {
  icon: React.ReactNode;
  header: string;
  description: string;
}

export const IconWithText = ({
  icon,
  header,
  description,
}: IconWithTextProps) => (
  <div className="flex flex-col gap-12 md:gap-32">
    <div className="flex flex-row items-start gap-16 text-14-20 font-medium md:gap-24 md:text-16-24">
      <div className="rounded-[12px] text-text-base-1 p-10 shadow-d md:p-12 bg-background-base-1">
        {icon}
      </div>
      <div className="flex flex-col pt-10">
        <div className="text-text-base-1">{header}</div>
        <div className="text-text-base-4">{description}</div>
      </div>
    </div>
  </div>
);

interface ApplicationCardProps {
  children: React.ReactNode;
}

export const ApplicationCard = ({ children }: ApplicationCardProps) => (
  <div className="flex h-full max-h-[88px] w-full flex-col items-center justify-between gap-8 rounded-ms bg-white px-8 py-20 text-center shadow-f">
    {children}
  </div>
);

export const ProductOverviewSection = () => {
  return (
    <div className="container-lg mx-12 grid max-w-[1160px] grid-cols-12 text-center md:mx-auto">
      <div className="col-span-full flex flex-col justify-start text-start">
        <div className="justify-start text-13-14 font-bold text-blue-400">
          Humanloop
        </div>
        <h2 className="mt-16 justify-start text-start font-display text-[24px] leading-[100%] text-text-base-1 md:mt-40 md:text-[40px] ">
          A shared workspace where PMs, Engineers and Domain Experts collaborate
          on building AI features
        </h2>
      </div>
      <Image
        className="sm:hidden mt-40 col-span-full"
        src="/assets/product/product-square-2@3x.png"
        width={520}
        height={440}
        alt="Evaluate prompts"
      />
      <div className="col-span-full mt-32 text-left sm:col-span-5 md:mt-64">
        <div className="flex flex-col gap-32 md:gap-40">
          <div className="text-center md:mx-auto">
            <div className="flex flex-col text-left space-y-28 text-14-20 md:text-18-28 text-text-base-2 max-w-lg">
              <p>
                <strong>Humanloop is the first platform</strong> that combines
                software best practices with the needs of LLMs in a unified
                platform. Empowering your whole team to drive AI improvement.
              </p>
            </div>
          </div>

          <div className="flex max-w-[350px] flex-col gap-24 md:gap-60">
            <IconWithText
              icon={<UserIcon className="h-16 w-16" />}
              header={"Best-in-class Playground"}
              description={
                "Manage and iterate on prompts across your organization"
              }
            />
            <IconWithText
              icon={<ChartBarIcon className="h-16 w-16" />}
              header={"Evaluation + monitoring suite"}
              description={
                "Debug prompts, chains or agents before shipping to production"
              }
            />
            <IconWithText
              icon={<ModelIcon className="h-16 w-16" />}
              header={"Customization + optimization tools"}
              description={
                "Connect private data and fine-tune for differentiated model performance"
              }
            />
          </div>
        </div>
      </div>

      <div className="hidden sm:flex col-start-7 col-end-13 mt-64 items-center justify-center text-left gap-32">
        <div className="flex flex-col h-full w-full gap-32">
          <Image
            src="/assets/product/product-square-4@3x.png"
            width={288}
            height={242}
            alt="Manage prompts"
          />
          <Image
            src="/assets/product/product-square-3@3x.png"
            width={288}
            height={308}
            alt="Optimise Performance"
            title="Optimise Performance"
          />
        </div>
        <div className="md:flex pt-[104px] hidden flex-col h-full w-full gap-16">
          <Image
            src="/assets/product/product-square-2@3x.png"
            width={288}
            height={280}
            alt="Best in class Playground"
          />

          <Image
            src="/assets/product/product-square-1@3x.png"
            width={288}
            height={242}
            alt="Evaluate prompts"
          />
        </div>
      </div>
    </div>
  );
};
