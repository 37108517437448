import Button, { InlineLink } from "@components/library/Button";
import { HUMANLOOP_APP_SIGNUP, DEMO_FORM_LINK } from "utils/constants";
import {
  analytics,
  EventPage,
  EventPurpose,
  EventSection,
} from "services/analytics.service";
import { useBreakpoints } from "hooks/useBreakpoints";
import Image from "next/image";
import { Arrow } from "@radix-ui/react-tooltip";
import { ArrowRightIcon } from "@heroicons/react/outline";

export const BottomCTA = () => {
  const { isMediumWidth } = useBreakpoints();

  return (
    <div className="container-lg grid-12 mx-12 max-w-[1160px] text-center md:mx-auto">
      <div className="col-span-full text-left md:col-span-6">
        <h2 className="mt-48 self-stretch font-display text-[32px] leading-[90%] text-text-base-5 md:mt-[112px] md:text-[64px] ">
          Accelerate your AI use cases
        </h2>
        <p className="text-text-base-5 max-w-md mt-20">
          Book a 1:1 demo for a guided tour of the platform tailored to your
          organization.
        </p>
        <div className="mb-48 mt-32 flex flex-row items-start justify-start gap-12 md:mb-[112px] md:mt-40">
          <div className="flex w-full flex-col items-center gap-12 md:w-[260px]">
            <Button
              className="w-full bg-white font-bold !text-gray-900 hover:!text-white"
              type="submit"
              size={isMediumWidth ? 52 : 40}
              styling="solid"
              shade="gray"
              IconRight={ArrowRightIcon}
              href={DEMO_FORM_LINK}
              onClick={() => {
                analytics.track("Button Clicked", {
                  page: EventPage.Home,
                  section: EventSection.FinalCTA,
                  purpose: EventPurpose.Sales,
                  url: DEMO_FORM_LINK,
                });
              }}
            >
              Book a demo
            </Button>
            <div className="text-11-12 text-gray-200 md:text-12-12">
              <InlineLink
                href={HUMANLOOP_APP_SIGNUP}
                onClick={() => {
                  analytics.track("Button Clicked", {
                    page: EventPage.Home,
                    section: EventSection.Hero,
                    purpose: EventPurpose.Signup,
                    url: HUMANLOOP_APP_SIGNUP,
                  });
                }}
              >
                Or try for free
              </InlineLink>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-full hidden md:col-span-6 md:flex">
        <div className="relative  flex items-center justify-center w-full h-full">
          <Image
            className="rounded-xl relative left-[72px] shadow-5 top-140 h-120"
            src="/assets/headshots/avatar-raza@3x.png"
            width={360}
            height={360}
            alt="Raza"
          />
          <Image
            className="rounded-ms"
            src="/assets/product/editor-mockup@2x.png"
            width={1080}
            height={676}
            alt="Editor screenshot"
          />

          <Image
            className="rounded-xl relative -left-[72px] -top-120 h-120 shadow-5"
            src="/assets/headshots/avatar-timo@3x.png"
            width={360}
            height={360}
            alt="Timo"
          />
        </div>
      </div>
    </div>
  );
};
