import { BottomCTA } from "@components/home/BottomCTA";
import { DemoPlayerSection } from "@components/home/DemoPlayerSection";
import { DevelopersAndOthersSection } from "@components/home/DevelopersAndOthersSection";
import { DevelopersCarouselSection } from "@components/home/DevelopersCarouselSection";
import { EnterpriseCarouselSection } from "@components/home/EnterpriseCarouselSection";
import { EnterpriseSection } from "@components/home/EnterpriseSection";
import { GetStartedSection } from "@components/home/GetStartedSection";
import { HeroSection } from "@components/home/HeroSection";
import { ProductOverviewSection } from "@components/home/ProductOverviewSection";
import { TheProblemSection } from "@components/home/TheProblemSection";
import { TrustedByCompanies } from "@components/home/TrustedByCompanies";
import { DarkGridBackground } from "@components/home/backgrounds/DarkGridBackground";
import { GridBackground } from "@components/home/backgrounds/GridBackground";
import Layout from "@components/layout/Layout";
import { WebsiteGradient } from "@components/library/Images/WebsiteGradient";
import { TitleAndMetaTags } from "components/TitleAndMetaTags";
import { ReactElement } from "react";

const Home: NextPageTypes.NextPageWithLayout = () => (
  <div>
    <TitleAndMetaTags
      description="Manage your prompts, evaluate your chains, quickly build production-grade applications with Large Language Models."
      image="/assets/marketing/social/social-gradient.png"
    />

    <main className="mx-auto">
      <div className="overflow-x-hidden pb-[20px] pt-[40px] md:pb-[70px] md:pt-[100px]">
        <GridBackground>
          <HeroSection />
        </GridBackground>
      </div>
      <div className="pb-[48px] pt-[40px]">
        <TrustedByCompanies />
      </div>
      <div className="relative overflow-hidden">
        <DemoPlayerSection />
        <div className="absolute bottom-[0px] left-0 right-0 top-[60%] bg-bottom bg-no-repeat">
          <WebsiteGradient className="flex h-full w-full" />
        </div>
      </div>

      <div className="py-[48px] md:py-[112px]">
        <TheProblemSection />
      </div>
      <div className="bg-white py-[48px] md:py-[112px]">
        <ProductOverviewSection />
      </div>
      <div className="bg-gray-950 pt-[48px] md:pt-[112px]">
        <DevelopersAndOthersSection />
      </div>
      <div className="dark bg-gray-950 pb-[48px] pt-20 md:pb-[64px]">
        <DevelopersCarouselSection />
      </div>
      <div className="py-[48px] md:py-[112px]">
        <EnterpriseSection />
      </div>
      <div className="pt-[30px] md:pb-[64px] md:pt-[74px]">
        <EnterpriseCarouselSection />
      </div>

      <div className="overflow-hidden">
        <DarkGridBackground>
          <div className="dark border-gray-800">
            <BottomCTA />
          </div>
        </DarkGridBackground>
      </div>
      <div className="bg-gray-950 py-[32px] md:py-[64px]">
        <TrustedByCompanies isDark />
      </div>
    </main>
  </div>
);

Home.getLayout = function getLayout(page: ReactElement) {
  return (
    <Layout xlWidth noTags>
      {page}
    </Layout>
  );
};

export default Home;
